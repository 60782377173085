const getDefaultState = () => {
  return {
    id_societaRichiesteOmologazione: null,
    denominazioneSocietaRichiesteOmologazione: "",
    id_omologatoreRichiesteOmologazione: null,
    denominazioneOmologatoreRichiesteOmologazione: "",
    tipologieRichiesteOmologazione: "5",
    disciplineRichiesteOmologazione: null,
    rowstoskipRichiesteOmologazione: 0,
    fetchrowsRichiesteOmologazione: 25,
    currentpageRichiesteOmologazione: 1,
    sortcolumnRichiesteOmologazione: "data_modifica_stato",
    sortorderRichiesteOmologazione: "desc",
    tutteLeStagioniRichiesteOmologazione: false,
  };
};
const state = getDefaultState();

const getters = {
  id_societaRichiesteOmologazione: (state) =>
    state.id_societaRichiesteOmologazione,
  id_omologatoreRichiesteOmologazione: (state) =>
    state.id_omologatoreRichiesteOmologazione,
  denominazioneSocietaRichiesteOmologazione: (state) =>
    state.denominazioneSocietaRichiesteOmologazione,
  denominazioneOmologatoreRichiesteOmologazione: (state) =>
    state.denominazioneOmologatoreRichiesteOmologazione,
  tipologieRichiesteOmologazione: (state) =>
    state.tipologieRichiesteOmologazione,
  disciplineRichiesteOmologazione: (state) =>
    state.disciplineRichiesteOmologazione,
  rowstoskipRichiesteOmologazione: (state) =>
    state.rowstoskipRichiesteOmologazione,
  fetchrowsRichiesteOmologazione: (state) =>
    state.fetchrowsRichiesteOmologazione,
  currentpageRichiesteOmologazione: (state) =>
    state.currentpageRichiesteOmologazione,
  sortcolumnRichiesteOmologazione: (state) =>
    state.sortcolumnRichiesteOmologazione,
  sortorderRichiesteOmologazione: (state) =>
    state.sortorderRichiesteOmologazione,
  tutteLeStagioniRichiesteOmologazione: (state) =>
    state.tutteLeStagioniRichiesteOmologazione,
};

// const actions = {};
const mutations = {
  setid_societaRichiesteOmologazione: (state, value) => {
    state.id_societaRichiesteOmologazione = value;
  },
  setid_omologatoreRichiesteOmologazione: (state, value) => {
    state.id_omologatoreRichiesteOmologazione = value;
  },
  setdenominazioneSocietaRichiesteOmologazione: (state, value) => {
    state.denominazioneSocietaRichiesteOmologazione = value;
  },
  setdenominazioneOmologatoreRichiesteOmologazione: (state, value) => {
    state.denominazioneOmologatoreRichiesteOmologazione = value;
  },
  settipologieRichiesteOmologazione: (state, value) => {
    state.tipologieRichiesteOmologazione = value;
  },
  setdisciplineRichiesteOmologazione: (state, value) => {
    state.disciplineRichiesteOmologazione = value;
  },
  setfetchrowsRichiesteOmologazione: (state, value) => {
    console.log(value);
    state.currentpageRichiesteOmologazione = 1;
    state.rowstoskipRichiesteOmologazione = 0;
    state.fetchrowsRichiesteOmologazione = value;
  },
  setcurrentpageRichiesteOmologazione: (state, value) => {
    state.currentpageRichiesteOmologazione = value;
    state.rowstoskipRichiesteOmologazione = parseInt(
      (state.currentpageRichiesteOmologazione - 1) *
        state.fetchrowsRichiesteOmologazione
    );
  },
  setsortcolumnRichiesteOmologazione: (state, value) => {
    state.sortcolumnRichiesteOmologazione = value;
  },
  setsortorderRichiesteOmologazione: (state, value) => {
    state.sortorderRichiesteOmologazione = value;
  },
  setrowstoskipRichiesteOmologazione: (state) => {
    state.currentpageRichiesteOmologazione = 1;
    state.rowstoskipRichiesteOmologazione = 0;
  },
  setTutteLeStagioniRichiesteOmologazione: (state) => {
    state.tutteLeStagioniRichiesteOmologazione =
      !state.tutteLeStagioniRichiesteOmologazione;
    state.currentpageRichiesteOmologazione = 1;
    state.rowstoskipRichiesteOmologazione = 0;
  },
  resetFiltersRichiesteOmologazione: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
