const getDefaultState = () => {
  return {
    id_societaRifiutateOmologazione: null,
    denominazioneSocietaRifiutateOmologazione: "",
    id_omologatoreRifiutateOmologazione: null,
    denominazioneOmologatoreRifiutateOmologazione: "",
    tipologieRifiutateOmologazione: "3",
    disciplineRifiutateOmologazione: null,
    rowstoskipRifiutateOmologazione: 0,
    fetchrowsRifiutateOmologazione: 25,
    currentpageRifiutateOmologazione: 1,
    sortcolumnRifiutateOmologazione: "societa",
    sortorderRifiutateOmologazione: "asc",
    tutteLeStagioniRifiutateOmologazione: false,
  };
};
const state = getDefaultState();

const getters = {
  id_societaRifiutateOmologazione: (state) =>
    state.id_societaRifiutateOmologazione,
  id_omologatoreRifiutateOmologazione: (state) =>
    state.id_omologatoreRifiutateOmologazione,
  denominazioneSocietaRifiutateOmologazione: (state) =>
    state.denominazioneSocietaRifiutateOmologazione,
  denominazioneOmologatoreRifiutateOmologazione: (state) =>
    state.denominazioneOmologatoreRifiutateOmologazione,
  tipologieRifiutateOmologazione: (state) =>
    state.tipologieRifiutateOmologazione,
  disciplineRifiutateOmologazione: (state) =>
    state.disciplineRifiutateOmologazione,
  rowstoskipRifiutateOmologazione: (state) =>
    state.rowstoskipRifiutateOmologazione,
  fetchrowsRifiutateOmologazione: (state) =>
    state.fetchrowsRifiutateOmologazione,
  currentpageRifiutateOmologazione: (state) =>
    state.currentpageRifiutateOmologazione,
  sortcolumnRifiutateOmologazione: (state) =>
    state.sortcolumnRifiutateOmologazione,
  sortorderRifiutateOmologazione: (state) =>
    state.sortorderRifiutateOmologazione,
  tutteLeStagioniRifiutateOmologazione: (state) =>
    state.tutteLeStagioniRifiutateOmologazione,
};

// const actions = {};
const mutations = {
  setid_societaRifiutateOmologazione: (state, value) => {
    state.id_societaRifiutateOmologazione = value;
  },
  setid_omologatoreRifiutateOmologazione: (state, value) => {
    state.id_omologatoreRifiutateOmologazione = value;
  },
  setdenominazioneSocietaRifiutateOmologazione: (state, value) => {
    state.denominazioneSocietaRifiutateOmologazione = value;
  },
  setdenominazioneOmologatoreRifiutateOmologazione: (state, value) => {
    state.denominazioneOmologatoreRifiutateOmologazione = value;
  },
  settipologieRifiutateOmologazione: (state, value) => {
    state.tipologieRifiutateOmologazione = value;
  },
  setdisciplineRifiutateOmologazione: (state, value) => {
    state.disciplineRifiutateOmologazione = value;
  },
  setfetchrowsRifiutateOmologazione: (state, value) => {
    console.log(value);
    state.currentpageRifiutateOmologazione = 1;
    state.rowstoskipRifiutateOmologazione = 0;
    state.fetchrowsRifiutateOmologazione = value;
  },
  setcurrentpageRifiutateOmologazione: (state, value) => {
    state.currentpageRifiutateOmologazione = value;
    state.rowstoskipRifiutateOmologazione = parseInt(
      (state.currentpageRifiutateOmologazione - 1) *
        state.fetchrowsRifiutateOmologazione
    );
  },
  setsortcolumnRifiutateOmologazione: (state, value) => {
    state.sortcolumnRifiutateOmologazione = value;
  },
  setsortorderRifiutateOmologazione: (state, value) => {
    state.sortorderRifiutateOmologazione = value;
  },
  setrowstoskipRifiutateOmologazione: (state) => {
    state.currentpageRifiutateOmologazione = 1;
    state.rowstoskipRifiutateOmologazione = 0;
  },
  setTutteLeStagioniRifiutateOmologazione: (state) => {
    state.tutteLeStagioniRifiutateOmologazione =
      !state.tutteLeStagioniRifiutateOmologazione;
    state.currentpageRifiutateOmologazione = 1;
    state.rowstoskipRifiutateOmologazione = 0;
  },
  resetFiltersRifiutateOmologazione: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
