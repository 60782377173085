const debugPrint = true;
import services from "../../axios/dbManag";

const getLookupRegProvCom = () => {
  return services.dbManag
    .post(`/combolist/get`, {
      keys: "irp",
    })
    .then((res) => {
      let regioniProvince = [];

      res.data.result[0].istat_regioni_province.forEach((regione) => {
        regioniProvince.push(regione);
      });

      return {
        regioniProvince,
      };
    });
};

const getComuniIstatApi = (nomec, codp) => {
  return services.dbManag
    .post(`lookup/comuni/istat/list`, {
      nomec,
      codp,
      attivo: true,
      // id_cr_ruolo: 0,
      // id_societa_ruolo: 0,
      // id_insegnante_ruolo: 0,
      // id_utente: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    })
    .then((res) => {
      return res.data.comuni_istat;
    })
    .catch((e) => {
      console.log("ERRORE COMUNI: " + e);
    });
};
const getComuniNascitaApi = (nomec) => {
  return services.dbManag
    .post(`lookup/comuni/nascita/list`, {
      nomec,
      id_cr_ruolo: 0,
      id_societa_ruolo: 0,
      id_insegnante_ruolo: 0,
      id_utente: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    })
    .then((res) => {
      return res.data.comuni_nascita;
    })
    .catch((e) => {
      console.log("ERRORE COMUNI: " + e);
    });
};

const state = {
  regioni: [],
  provinceFiltrate: [],
  comuniIstat: [],
  comuniNascita: [],
};

const getters = {
  regioni: (state) => state.regioni,
  comuniIstat: (state) => state.comuniIstat,
  comuniNascita: (state) => state.comuniNascita,
  provinceFiltrate: (state) => state.provinceFiltrate,
};
const actions = {
  lookupsRegProvCom: async (state) => {
    let res = await getLookupRegProvCom();
    state.commit("setLookUpsRegProvCom", res);
  },
  filterProvince: (state, selectedReg) => {
    state.commit("setLookupProv", selectedReg);
  },
  getComuniIstat: async (state, { string, id_prov }) => {
    let res = await getComuniIstatApi(string, id_prov);
    state.commit("setComuniIstat", res);
  },
  getComuniNascita: async (state, string) => {
    let res = await getComuniNascitaApi(string);
    state.commit("setComuniNascita", res);
  },
};
const mutations = {
  setLookUpsRegProvCom: (state, { regioniProvince }) => {
    regioniProvince.forEach((regione) => {
      state.regioni.push(regione);
    });
  },
  setLookupProv: (state, selectedRegione) => {
    state.provinceFiltrate.splice(0, state.provinceFiltrate.length);
    state.regioni.forEach((regione) => {
      if (regione.codR === selectedRegione) {
        regione.Province.forEach((provincia) => {
          state.provinceFiltrate.push(provincia);
        });
      }
    });
    // }
  },
  spliceComuniIstat: (state) => {
    state.comuniIstat.splice(0, state.comuniIstat.length);
  },
  setComuniIstat: (state, comuniList) => {
    comuniList.forEach((comune) => {
      state.comuniIstat.push(comune);
    });
  },
  spliceComuniNascita: (state) => {
    state.comuniNascita.splice(0, state.comuniNascita.length);
  },
  setComuniNascita: (state, comuniList) => {
    comuniList.forEach((comune) => {
      state.comuniNascita.push(comune);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
