const getDefaultState = () => {
  return {
    id_societaEsitoOmologazione: null,
    denominazioneSocietaEsitoOmologazione: "",
    id_omologatoreEsitoOmologazione: null,
    denominazioneOmologatoreEsitoOmologazione: "",
    tipologieEsitoOmologazione: "1, 3",
    disciplineEsitoOmologazione: null,
    rowstoskipEsitoOmologazione: 0,
    fetchrowsEsitoOmologazione: 25,
    currentpageEsitoOmologazione: 1,
    sortcolumnEsitoOmologazione: "societa",
    sortorderEsitoOmologazione: "asc",
    tutteLeStagioniEsitoOmologazione: false,
  };
};
const state = getDefaultState();

const getters = {
  id_societaEsitoOmologazione: (state) => state.id_societaEsitoOmologazione,
  id_omologatoreEsitoOmologazione: (state) =>
    state.id_omologatoreEsitoOmologazione,
  denominazioneSocietaEsitoOmologazione: (state) =>
    state.denominazioneSocietaEsitoOmologazione,
  denominazioneOmologatoreEsitoOmologazione: (state) =>
    state.denominazioneOmologatoreEsitoOmologazione,
  tipologieEsitoOmologazione: (state) => state.tipologieEsitoOmologazione,
  disciplineEsitoOmologazione: (state) => state.disciplineEsitoOmologazione,
  rowstoskipEsitoOmologazione: (state) => state.rowstoskipEsitoOmologazione,
  fetchrowsEsitoOmologazione: (state) => state.fetchrowsEsitoOmologazione,
  currentpageEsitoOmologazione: (state) => state.currentpageEsitoOmologazione,
  sortcolumnEsitoOmologazione: (state) => state.sortcolumnEsitoOmologazione,
  sortorderEsitoOmologazione: (state) => state.sortorderEsitoOmologazione,
  tutteLeStagioniEsitoOmologazione: (state) =>
    state.tutteLeStagioniEsitoOmologazione,
};

// const actions = {};
const mutations = {
  setid_societaEsitoOmologazione: (state, value) => {
    state.id_societaEsitoOmologazione = value;
  },
  setid_omologatoreEsitoOmologazione: (state, value) => {
    state.id_omologatoreEsitoOmologazione = value;
  },
  setdenominazioneSocietaEsitoOmologazione: (state, value) => {
    state.denominazioneSocietaEsitoOmologazione = value;
  },
  setdenominazioneOmologatoreEsitoOmologazione: (state, value) => {
    state.denominazioneOmologatoreEsitoOmologazione = value;
  },
  settipologieEsitoOmologazione: (state, value) => {
    state.tipologieEsitoOmologazione = value;
  },
  setdisciplineEsitoOmologazione: (state, value) => {
    state.disciplineEsitoOmologazione = value;
  },
  setfetchrowsEsitoOmologazione: (state, value) => {
    console.log(value);
    state.currentpageEsitoOmologazione = 1;
    state.rowstoskipEsitoOmologazione = 0;
    state.fetchrowsEsitoOmologazione = value;
  },
  setcurrentpageEsitoOmologazione: (state, value) => {
    state.currentpageEsitoOmologazione = value;
    state.rowstoskipEsitoOmologazione = parseInt(
      (state.currentpageEsitoOmologazione - 1) *
        state.fetchrowsEsitoOmologazione
    );
  },
  setsortcolumnEsitoOmologazione: (state, value) => {
    state.sortcolumnEsitoOmologazione = value;
  },
  setsortorderEsitoOmologazione: (state, value) => {
    state.sortorderEsitoOmologazione = value;
  },
  setrowstoskipEsitoOmologazione: (state) => {
    state.currentpageEsitoOmologazione = 1;
    state.rowstoskipEsitoOmologazione = 0;
  },
  setTutteLeStagioniEsitoOmologazione: (state) => {
    state.tutteLeStagioniEsitoOmologazione =
      !state.tutteLeStagioniEsitoOmologazione;
    state.currentpageEsitoOmologazione = 1;
    state.rowstoskipEsitoOmologazione = 0;
  },
  resetFiltersEsitoOmologazione: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
