const getDefaultStateEconomatoSnm = () => {
  return {
    rowsToSkipEconomatoSnm: 0,
    fetchRowsEconomatoSnm: 25,
    currentPageEconomatoSnm: 1,
    sortColumnEconomatoSnm: "numero",
    sortOrderEconomatoSnm: "asc",
    tutteStagioniEconomatoSnm: false,
    viewPagaSelezionati: true,
  };
};
const state = getDefaultStateEconomatoSnm();

const getters = {
  rowsToSkipEconomatoSnm: (state) => state.rowsToSkipEconomatoSnm,
  fetchRowsEconomatoSnm: (state) => state.fetchRowsEconomatoSnm,
  currentPageEconomatoSnm: (state) => state.currentPageEconomatoSnm,
  sortColumnEconomatoSnm: (state) => state.sortColumnEconomatoSnm,
  sortOrderEconomatoSnm: (state) => state.sortOrderEconomatoSnm,
  tutteStagioniEconomatoSnm: (state) => state.tutteStagioniEconomatoSnm,
  viewPagaSelezionati: (state) => state.viewPagaSelezionati,
};

// const actions = {};
const mutations = {
  setFetchRowsEconomatoSnm: (state, value) => {
    state.currentPageEconomatoSnm = 1;
    state.rowsToSkipEconomatoSnm = 0;
    state.fetchRowsEconomatoSnm = value;
  },
  setCurrentPagEconomatoSnm: (state, value) => {
    state.currentPageEconomatoSnm = value;
    state.rowsToSkipEconomatoSnm = parseInt(
      (state.currentPageEconomatoSnm - 1) * state.fetchRowsEconomatoSnm
    );
  },
  setSortColumnEconomatoSnm: (state, value) => {
    state.sortColumnEconomatoSnm = value;
  },
  setSortOrderEconomatoSnm: (state, value) => {
    state.sortOrderEconomatoSnm = value;
  },
  setCategoriaEconomatoSnm: (state, value) => {
    state.id_categoriaEconomatoSnm = value;
  },
  resetRowsToSkipAPSNM: (state) => {
    state.rowsToSkipEconomatoSnm = 0;
  },
  setTutteLeStagioniEconomatoSnm: (state) => {
    state.tutteStagioniEconomatoSnm = !state.tutteStagioniEconomatoSnm;
    state.currentPageEconomatoSnm = 1;
    state.rowsToSkipEconomatoSnm = 0;
  },
  setViewPagaSelezionati: (state, value) => {
    console.log(value);
    state.viewPagaSelezionati = value;
  },
  resetFilterEconomatoPersonale: (state) => {
    Object.assign(state, getDefaultStateEconomatoSnm());
  },
};

export default {
  state,
  getters,
  mutations,
};
