import Swal from "sweetalert2/dist/sweetalert2.min.js";

export const alertSuccess = (message, idModalToClose) => {
  const confirmButtonText = `
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-5"
                data-bs-dismiss="modal"
                data-bs-target="#${idModalToClose}"
              >
                Chiudi
              </span>`;
  Swal.fire({
    html: message,
    icon: "success",
    buttonsStyling: false,
    customClass: idModalToClose
      ? ""
      : {
          confirmButton: "me-3 badge bg-light-danger rounded text-danger fs-5",
        },
    confirmButtonText: idModalToClose ? confirmButtonText : "Chiudi",
    allowOutsideClick: false,
  });
};

export const alertFailed = (message, idModalToClose) => {
  const confirmButtonText = `
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-5"
                data-bs-dismiss="modal"
                data-bs-target="#${idModalToClose}"
              >
                Chiudi
              </span>`;
  return Swal.fire({
    html: message,
    icon: "error",
    buttonsStyling: false,
    customClass: idModalToClose
      ? ""
      : {
          confirmButton: "me-3 badge bg-light-danger rounded text-danger fs-5",
        },
    confirmButtonText: idModalToClose ? confirmButtonText : "Chiudi",
    allowOutsideClick: false,
  }).then((e) => {
    if (e.isDismissed) {
      return false;
    }
    return true;
  });
};

export const alertChoice = (
  message,
  text,
  cancelButtonText,
  confirmButtonText
) => {
  Swal.fire({
    title: message,
    text: text,
    buttonsStyling: false,
    showCancelButton: true,
    cancelButtonText,
    confirmButtonText,
    customClass: {
      confirmButton: "btn fw-bold btn-light-danger",
      cancelButton: "btn fw-bold btn-light-primary",
    },
  }).then((e) => {
    if (e.isDismissed) {
      return false;
    }
    return true;
  });
};
