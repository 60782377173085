import services from "../../axios/dbManag";
import { trimInput } from "@/composables/trimInput";
import { useFormatDateYMDPersoneList } from "@/composables/formatDate";

const getPersoneAlboSnm = (nome, cognome, data_nascita, anno_nascita) => {
  return services.dbManag
    .post("/snm/persone/ricerca", {
      cognome: trimInput(cognome),
      nome: trimInput(nome),
      data_nascita:
        data_nascita && data_nascita.length >= 10
          ? useFormatDateYMDPersoneList(trimInput(data_nascita))
          : null,
      anno: anno_nascita,
      tipo_albo: 1,
      fetchrows: 15,
      rowstoskip: 0,
      sortcolumn: "cognome",
      sortorder: "asc",
    })
    .then((res) => {
      const persone = [];
      const record = res.data.record;
      const comandiLocked = res.data.comandi_locked;
      res.data.results.forEach((person) => {
        persone.push(person);
      });
      return {
        comandiLocked,
        record,
        persone,
      };
    })
    .catch(() => {
      state.errorPersone = true;
    });
};
const getSanzioniPersone = (
  id_societa,
  stagione,
  nome,
  cognome,
  data_nascita,
  anno_nascita
) => {
  return services.dbManag
    .post("/lookup/sanzioni/persone/ricerca ", {
      id_societa: id_societa,
      stagione: stagione,
      cognome: trimInput(cognome),
      nome: trimInput(nome),
      data_nascita:
        data_nascita && data_nascita.length >= 10
          ? useFormatDateYMDPersoneList(trimInput(data_nascita))
          : null,
      anno: anno_nascita,
      fetchrows: 15,
      rowstoskip: 0,
      sortcolumn: "cognome",
      sortorder: "asc",
    })
    .then((res) => {
      const persone = [];
      const record = res.data.record;
      const comandiLocked = res.data.comandi_locked;
      res.data.results.forEach((person) => {
        persone.push(person);
      });
      return {
        comandiLocked,
        record,
        persone,
      };
    })
    .catch(() => {
      state.errorPersone = true;
    });
};
const getPersoneNomeCognomeApi = (
  nome,
  cognome,
  data_nascita,
  anno_nascita
) => {
  return services.dbManag
    .post("/lookup/persone/ricerca/list", {
      cognome: trimInput(cognome),
      nome: trimInput(nome),
      data_nascita:
        data_nascita && data_nascita.length >= 10
          ? useFormatDateYMDPersoneList(trimInput(data_nascita))
          : null,
      anno: anno_nascita,
      fetchrows: 15,
      rowstoskip: 0,
      sortcolumn: "cognome",
      sortorder: "asc",
    })
    .then((res) => {
      const persone = [];
      const record = res.data.record;
      const comandiLocked = res.data.comandi_locked;
      res.data.results.forEach((person) => {
        persone.push(person);
      });
      return {
        comandiLocked,
        record,
        persone,
      };
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getOmologatoriApi = (nome, cognome, data_nascita, anno_nascita) => {
  return services.dbManag
    .post("/lookup/persone/omologatori/search", {
      cognome: trimInput(cognome),
      nome: trimInput(nome),
      data_nascita:
        data_nascita && data_nascita.length >= 10
          ? useFormatDateYMDPersoneList(trimInput(data_nascita))
          : null,
      anno: anno_nascita,
      fetchrows: 15,
      rowstoskip: 0,
      sortcolumn: "cognome",
      sortorder: "asc",
    })
    .then((res) => {
      const persone = [];
      const record = res.data.record;
      const comandiLocked = res.data.comandi_locked;
      res.data.results.forEach((person) => {
        persone.push(person);
      });
      return {
        comandiLocked,
        record,
        persone,
      };
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getTecniciNomeCognomeApi = (
  nome,
  cognome,
  data_nascita,
  anno,
  id_Societa,
  tipo_Albo,
  id_Disciplina,
  anno_nascita,
  id_tipologia_scuola
) => {
  return services.dbManag
    .post("/lookup/scuole/nuova-richiesta/tecnici/ricerca", {
      anno,
      id_Societa,
      id_Disciplina,
      nome: trimInput(nome),
      cognome: trimInput(cognome),
      data_Nascita: data_nascita
        ? useFormatDateYMDPersoneList(trimInput(data_nascita))
        : null,
      tipo_Albo,
      rowstoskip: 0,
      fetchrows: 15,
      sortcolumn: "cognome",
      sortorder: "asc",
      anno_nascita,
      id_tipologia_scuola,
    })
    .then((res) => {
      const persone = [];
      const record = res.data.record;
      const comandiLocked = res.data.comandi_locked;
      res.data.results.forEach((person) => {
        persone.push(person);
      });
      return {
        comandiLocked,
        record,
        persone,
      };
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getPersoneListApi = (
  nome,
  cognome,
  cod_fisc,
  fetchRows,
  rowsToSkip,
  sortColumn,
  sortOrder,
  matricola,
  mail,
  cf_age_stato,
  stagione,
  tessera,
  tipoAlbo,
  tipoTessera,
  localita_nascita,
  data_nascita,
  anno_nascita
) => {
  return services.dbManag
    .post("/anagrafiche/persone/list", {
      nome: trimInput(nome),
      cognome: trimInput(cognome),
      cod_fisc: trimInput(cod_fisc),
      rowsToSkip,
      fetchRows,
      sortColumn,
      sortOrder,
      matricola: matricola,
      mail,
      cf_age_stato,
      stagione,
      tessera,
      tipoAlbo,
      tipoTessera,
      localita_nascita,
      data_nascita,
      anno: anno_nascita,
    })
    .then((res) => {
      const persone = [];
      const record = res.data.record;
      const comandiLocked = res.data.comandi_locked;
      res.data.results.forEach((person) => {
        persone.push(person);
      });
      return {
        comandiLocked,
        record,
        persone,
      };
    })
    .catch((e) => {
      return e.response.data;
    });
};

const getDefaultState = () => {
  return {
    persone: [],
    personeSocieta: [],
    totalPersone: 0,
    currentPagePersone: 1,
    comandiLocked: null,

    loadingPersone: true,
    errorPersone: false,

    nome: null,
    cognome: null,
    cod_fisc: null,

    rowToSkipPersone: 0,
    fetchRowsPersone: 25,
    sortColumnPersone: "cognome",
    sortOrderPersone: "asc",

    tessera: "",
    tipoTessera: null,
    tipoAlbo: null,
    tutteStagioniPersone: false,
    aeg: null,
    matricola: null,
    mail: null,

    localita_nascita: "",
    data_nascita: "",
  };
};
const state = getDefaultState();

const getters = {
  matricola: (state) => state.matricola,
  mail: (state) => state.mail,
  tessera: (state) => state.tessera,
  tipoTessera: (state) => state.tipoTessera,
  tipoAlbo: (state) => state.tipoAlbo,
  tutteStagioniPersone: (state) => state.tutteStagioniPersone,
  aeg: (state) => state.aeg,
  persone: (state) => state.persone,

  currentPagePersone: (state) => state.currentPagePersone,
  errorPersone: (state) => state.errorPersone,
  loadingPersone: (state) => state.loadingPersone,
  fetchRowsPersone: (state) => state.fetchRowsPersone,

  nome: (state) => state.nome,
  cognome: (state) => state.cognome,
  cod_fisc: (state) => state.cod_fisc,
  totalPersone: (state) => state.totalPersone,
  rowToSkipPersone: (state) => state.rowToSkipPersone,
  sortColumnPersone: (state) => state.sortColumnPersone,
  sortOrderPersone: (state) => state.sortOrderPersone,
  localita_nascita: (state) => state.localita_nascita,
  data_nascita: (state) => state.data_nascita,
  personeSocieta: (state) => state.personeSocieta,
};
const actions = {
  // chiamata all'inizio e con i filtri
  getPersoneList: async (stateObj) => {
    const { state } = stateObj;
    state.errorPersone = false;
    if (!state.loadingPersone) stateObj.commit("setLoading");
    const res = await getPersoneListApi(
      state.nome,
      state.cognome,
      state.cod_fisc,
      state.fetchRowsPersone,
      state.rowToSkipPersone,
      state.sortColumnPersone,
      state.sortOrderPersone,
      state.matricola,
      state.mail,
      state.aeg,
      state.tutteStagioniPersone
        ? 10000
        : stateObj.rootState.LookupStagione.stagioneSelected,
      // state.stagione,
      state.tessera,
      state.tipoAlbo,
      state.tipoTessera,
      state.localita_nascita,
      state.data_nascita,
      state
    );
    stateObj.commit("setPersoneList", res);
  },
  getPersoneNomeCognome: async (stateObj, string) => {
    let anno_nascita = null;
    let data_nascita = null;
    const cognome = string.split(",")[0];
    const nome = string.includes("-")
      ? string.substring(string.indexOf(",") + 1, string.indexOf("-")).trim()
      : string.split(",")[1];
    if (string.split(",")[2]) {
      if (
        (string.split(",")[2].length === 4 ||
          string.split(",")[2].length === 5) &&
        !string.split(",")[2].includes("/")
      ) {
        data_nascita = null;
        anno_nascita = string.split(",")[2].trim();
      } else if (
        string.split(",")[2].length === 10 ||
        string.split(",")[2].length === 11
      ) {
        anno_nascita = null;
        data_nascita = string.split(",")[2].trim();
      } else {
        anno_nascita = null;
        data_nascita = null;
      }
    }
    const res = await getPersoneNomeCognomeApi(
      nome,
      cognome,
      data_nascita,
      anno_nascita
    );
    stateObj.commit("setPersoneSocietaList", res);
  },
  getOmologatori: async (stateObj, { string }) => {
    console.log(string);
    let anno_nascita = null;
    let data_nascita = null;
    const cognome = string.split(",")[0];
    const nome = string.includes("-")
      ? string.substring(string.indexOf(",") + 1, string.indexOf("-")).trim()
      : string.split(",")[1];
    if (string.split(",")[2]) {
      if (
        (string.split(",")[2].length === 4 ||
          string.split(",")[2].length === 5) &&
        !string.split(",")[2].includes("/")
      ) {
        data_nascita = null;
        anno_nascita = string.split(",")[2].trim();
      } else if (
        string.split(",")[2].length === 10 ||
        string.split(",")[2].length === 11
      ) {
        anno_nascita = null;
        data_nascita = string.split(",")[2].trim();
      } else {
        anno_nascita = null;
        data_nascita = null;
      }
    }
    const res = await getOmologatoriApi(
      nome,
      cognome,
      data_nascita,
      anno_nascita
    );
    stateObj.commit("setPersoneSocietaList", res);
  },
  getAlboSnmPersone: async (stateObj, string) => {
    let anno_nascita = null;
    let data_nascita = null;
    const cognome = string.split(",")[0];
    const nome = string.includes("-")
      ? string.substring(string.indexOf(",") + 1, string.indexOf("-")).trim()
      : string.split(",")[1];
    if (string.split(",")[2]) {
      if (
        (string.split(",")[2].length === 4 ||
          string.split(",")[2].length === 5) &&
        !string.split(",")[2].includes("/")
      ) {
        data_nascita = null;
        anno_nascita = string.split(",")[2].trim();
      } else if (
        string.split(",")[2].length === 10 ||
        string.split(",")[2].length === 11
      ) {
        anno_nascita = null;
        data_nascita = string.split(",")[2].trim();
      } else {
        anno_nascita = null;
        data_nascita = null;
      }
    }
    const res = await getPersoneAlboSnm(
      nome,
      cognome,
      data_nascita,
      anno_nascita
    );
    stateObj.commit("setPersoneSocietaList", res);
  },
  getTecniciNomeCognome: async (
    stateObj,
    { string, anno, id_Societa, tipo_Albo, id_Disciplina, id_tipologia_scuola }
  ) => {
    let anno_nascita = null;
    let data_nascita = null;
    const cognome = string.split(",")[0];
    const nome = string.includes("-")
      ? string.substring(string.indexOf(",") + 1, string.indexOf("-")).trim()
      : string.split(",")[1];
    if (string.split(",")[2]) {
      if (
        (string.split(",")[2].length === 4 ||
          string.split(",")[2].length === 5) &&
        !string.split(",")[2].includes("/")
      ) {
        data_nascita = null;
        anno_nascita = string.split(",")[2].trim();
      } else if (
        string.split(",")[2].length === 10 ||
        string.split(",")[2].length === 11
      ) {
        anno_nascita = null;
        data_nascita = string.split(",")[2].trim();
      } else {
        anno_nascita = null;
        data_nascita = null;
      }
    }
    const res = await getTecniciNomeCognomeApi(
      nome,
      cognome,
      data_nascita,
      anno,
      id_Societa,
      tipo_Albo,
      id_Disciplina,
      anno_nascita,
      id_tipologia_scuola
    );

    stateObj.commit("setPersoneSocietaList", res);
  },
  getPersoneSanzioni: async (stateObj, { string, id_societa, stagione }) => {
    let anno_nascita = null;
    let data_nascita = null;
    const cognome = string.split(",")[0];
    const nome = string.includes("-")
      ? string.substring(string.indexOf(",") + 1, string.indexOf("-")).trim()
      : string.split(",")[1];
    if (string.split(",")[2]) {
      if (
        (string.split(",")[2].length === 4 ||
          string.split(",")[2].length === 5) &&
        !string.split(",")[2].includes("/")
      ) {
        data_nascita = null;
        anno_nascita = string.split(",")[2].trim();
      } else if (
        string.split(",")[2].length === 10 ||
        string.split(",")[2].length === 11
      ) {
        anno_nascita = null;
        data_nascita = string.split(",")[2].trim();
      } else {
        anno_nascita = null;
        data_nascita = null;
      }
    }
    const res = await getSanzioniPersone(
      id_societa,
      stagione,
      nome,
      cognome,
      data_nascita,
      anno_nascita
    );
    stateObj.commit("setPersoneSocietaList", res);
  },
  svuotaPersoneNomeCognome: (state) => {
    state.commit("emptyPersoneSocieta");
  },
};
const mutations = {
  emptyPersoneSocieta: (state) => {
    state.personeSocieta.splice(0, state.personeSocieta.length);
  },
  setStagione: (state, value) => {
    state.stagione = value;
  },
  setAeg: (state, value) => {
    state.aeg = value;
  },
  setMatricola: (state, value) => {
    state.matricola = value;
  },
  setMail: (state, value) => {
    state.mail = value;
  },
  setPersoneSocietaList: (state, { persone }) => {
    state.personeSocieta.splice(0, state.personeSocieta.length);
    persone.forEach((element) => {
      state.personeSocieta.push(element);
    });
  },
  setNome: (state, value) => {
    state.nome = value;
  },
  setCognome: (state, value) => {
    state.cognome = value;
  },
  setCod: (state, value) => {
    state.cod_fisc = value;
  },
  setPagePersone: (state, value) => {
    state.currentPagePersone = value;
    state.rowToSkipPersone = parseInt(
      (state.currentPagePersone - 1) * state.fetchRowsPersone
    );
  },
  setFetchRowsPersone: (state, value) => {
    state.currentPagePersone = 1;
    state.rowToSkipPersone = 0;
    state.fetchRowsPersone = value;
  },
  setRowsToSkipPersone: (state) => {
    state.currentPagePersone = 1;
    state.rowToSkipPersone = 0;
  },
  setLoading: (state) => {
    state.loadingPersone = !state.loadingPersone;
  },
  setPersoneList: (state, res) => {
    state.persone.splice(0, state.persone.length);
    if (res.statusCode) {
      state.errorPersone = true;
      return;
    }
    const { comandiLocked, record, persone } = res;
    state.comandiLocked = comandiLocked;
    state.totalPersone = record;
    persone.forEach((element) => {
      state.persone.push(element);
    });
    if (state.loadingPersone) state.loadingPersone = !state.loadingPersone;
  },

  setSortColumnPersone: (state, value) => {
    state.sortColumnPersone = value;
    state.rowToSkipPersone = 0;
    state.currentPagePersone = 1;
  },
  setSortOrderPersone: (state, value) => {
    state.sortOrderPersone = value;
    state.rowToSkipPersone = 0;
    state.currentPagePersone = 1;
  },

  resetPersoneList: (state) => {
    state.currentPagePersone = 1;
    state.nome = null;
    state.cognome = null;
    state.cod_fisc = null;
    state.matricola = null;
    state.mail = null;
    state.rowToSkipPersone = 0;
    state.sortColumnPersone = "cognome";
    state.sortOrderPersone = "asc";
    state.tipoTessera = null;
    state.tipoAlbo = null;
  },
  setTipoTessera: (state, value) => {
    state.tipoTessera = value;
  },
  setTipoAlbo: (state, value) => {
    state.tipoAlbo = value;
  },
  setTutteLeStagioniPersone: (state) => {
    state.tutteStagioniPersone = !state.tutteStagioniPersone;
    state.currentPagePersone = 1;
    state.rowToSkipPersone = 0;
  },
  resetFiltersPersone: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
