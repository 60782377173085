import services from "../../axios/dbManag";
import { trimInput } from "@/composables/trimInput";

const getImpiantiApi = (
  id_provincia,
  id_comune,
  denominazione,
  stato_omologazione,
  rowsToSkip,
  fetchRows,
  sortColumn,
  sortOrder,
  state
) => {
  return services.dbManag
    .post("/anagrafiche/impianti/list", {
      id_provincia,
      id_comune,
      denominazione: trimInput(denominazione),
      stato_omologazione,
      rowsToSkip,
      fetchRows,
      sortColumn,
      sortOrder,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
      // state.errorImpianti = true;
    });
};
const getDefaultState = () => {
  return {
    impianti: [],
    totalImpianti: 0,
    totalPagesImpianti: 0,
    currentPageImpianti: 1,
    selectedProvincia: null,
    stato_omologazione: null,
    rowsToSkipImpianti: 0,
    denominazione: null,
    id_provincia: null,
    id_comune: null,
    comuneTextImpianti: "",
    selectedProvinciaImpianti: false,
    fetchRowsImpianti: 25,
    sortColumnImpianti: "id",
    sortOrderImpianti: "DESC",

    errorImpianti: false,
    loadingImpianti: true,

    comandiLocked: "",
    campiLocked: "",
    ente_aggregatoImpianti: false,
  };
};
const state = getDefaultState();

const getters = {
  ente_aggregatoImpianti: (state) => state.ente_aggregatoImpianti,
  impianti: (state) => state.impianti,
  totalPagesImpianti: ({ totalPagesImpianti }) => {
    if (Number.isInteger(totalPagesImpianti)) return totalPagesImpianti;
    return parseInt(totalPagesImpianti) + 1;
  },
  currentPageImpianti: (state) => state.currentPageImpianti,
  errorImpianti: (state) => state.errorImpianti,
  selectedProvincia: (state) => state.selectedProvincia,
  stato_omologazione: (state) => state.stato_omologazione,
  denominazione: (state) => state.denominazione,
  id_provincia: (state) => state.id_provincia,
  id_comune: (state) => state.id_comune,
  loadingImpianti: (state) => state.loadingImpianti,
  totalImpianti: (state) => state.totalImpianti,
  rowsToSkipImpianti: (state) => state.rowsToSkipImpianti,
  fetchRowsImpianti: (state) => state.fetchRowsImpianti,
  sortColumnImpianti: (state) => state.sortColumnImpianti,
  sortOrderImpianti: (state) => state.sortOrderImpianti,
  comuneTextImpianti: (state) => state.comuneTextImpianti,
  selectedProvinciaImpianti: (state) => state.selectedProvinciaImpianti,
};
const actions = {
  getImpiantiFromApi: async (stateObj) => {
    const { state } = stateObj;
    state.errorImpianti = false;
    state.impianti.splice(0, state.impianti.length);
    if (!state.loadingImpianti) stateObj.commit("setLoading");
    const res = await getImpiantiApi(
      state.id_provincia,
      state.id_comune,
      state.denominazione,
      state.stato_omologazione,
      state.rowsToSkipImpianti,
      state.fetchRowsImpianti,
      state.sortColumnImpianti,
      state.sortOrderImpianti
    );
    stateObj.commit("setImpiantiList", res);
  },
};
const mutations = {
  setIsSelectedProvincia: (state, value) => {
    state.selectedProvinciaImpianti = value;
  },
  setSelectedComuneTextImpianti: (state, value) => {
    state.comuneTextImpianti = value;
  },
  setImpiantiList: (state, res) => {
    if (res.status != 200) {
      return;
    }
    res.data.results.forEach((impianto) => {
      state.impianti.push(impianto);
    });
    state.totalImpianti = res.data.record;
    state.comandiLocked = res.data.comandiLocked;
    state.campiLocked = res.data.campiLocked;
    if (state.loadingImpianti) state.loadingImpianti = !state.loadingImpianti;
  },
  setDenominazioneImpianti: (state, value) => {
    state.denominazione = value;
  },
  setstato_omologazione: (state, value) => {
    state.stato_omologazione = value;
  },
  setPageImpianti: (state, value) => {
    state.currentPageImpianti = value;
    state.rowsToSkipImpianti = parseInt(
      (state.currentPageImpianti - 1) * state.fetchRowsImpianti
    );
  },
  // setIdProvinciaImpianti: (state, value) => {
  //     state.id_provincia = value
  // },
  setFetchRowsImpianti: (state, value) => {
    state.fetchRowsImpianti = value;
  },
  setSortColumnImpianti: (state, value) => {
    state.sortColumnImpianti = value;
  },
  setSortOrderImpianti: (state, value) => {
    state.sortOrderImpianti = value;
  },

  setRowsToSkipImpianti: (state) => {
    state.currentPageImpianti = 1;
    state.rowsToSkipImpianti = 0;
  },

  resetImpianti: (state) => {
    state.selectedProvincia = "";
    state.currentPageImpianti = 1;
    state.denominazione = null;
    state.id_provincia = null;
    state.id_comune = null;
    state.rowsToSkipImpianti = 0;
    state.stato_omologazione = null;
  },
  // changeSelectedProvincia: (state, { nome, id }) => {
  //     state.selectedProvincia = nome;
  //     state.id_provincia = id;
  // },
  changeDenominazione: (state, value) => {
    state.denominazione = value;
  },
  setLoading: (state) => {
    state.loadingImpianti = !state.loadingImpianti;
  },
  setProvinciaImp: (state, value) => {
    state.id_provincia = value;
  },
  setComuneImp: (state, value) => {
    state.id_comune = value;
  },
  setEnteAggregatoImpianti: (state, value) => {
    state.ente_aggregatoImpianti = value;
  },
  resetFiltersImpianti: (state) => {
    Object.assign(state, getDefaultState());
  },
  setEnteAggregato: (state, value) => {
    state.ente_aggregatoImpianti = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
