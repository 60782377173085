const debugPrint = false;
import services from "../../axios/dbManag";

const getLookup = () => {
  return services.dbManag
    .post(`/combolist/get`, {
      keys: "lp|tdr|t|tdi|sn|sc|ts|sr",
    })
    .then((res) => {
      let lingueParlateLookup = [];
      let tipiDocRilascioLookup = [];
      let taglieLookup = [];
      let tipiDocIdentitaLookup = [];
      let statiNascitaLookup = [];
      let statiCittadinanzaLookup = [];

      let titoliStudioLookup = [];
      let statiResidenzaLookup = [];
      res.data.result[0].lingue_parlate.forEach((lingua) => {
        lingueParlateLookup.push(lingua);
      });
      res.data.result[1].tipo_docum_rilascio.forEach((documento) => {
        tipiDocRilascioLookup.push(documento);
      });
      res.data.result[2].taglie.forEach((taglia) => {
        taglieLookup.push(taglia);
      });
      res.data.result[3].tipo_docum_identita.forEach((documento) => {
        tipiDocIdentitaLookup.push(documento);
      });
      res.data.result[4].stati_nascita.forEach((stato) => {
        statiNascitaLookup.push(stato);
      });
      res.data.result[5].stati_cittadinanza.forEach((stato) => {
        statiCittadinanzaLookup.push(stato);
      });
      res.data.result[6].titoli_studio.forEach((titolo) => {
        titoliStudioLookup.push(titolo);
      });
      res.data.result[7].stati_residenza.forEach((nazione) => {
        statiResidenzaLookup.push(nazione);
      });

      return {
        lingueParlateLookup,
        tipiDocRilascioLookup,
        taglieLookup,
        tipiDocIdentitaLookup,
        statiNascitaLookup,
        statiCittadinanzaLookup,
        titoliStudioLookup,
        statiResidenzaLookup,
      };
    })
    .catch((e) => {
      console.log(e.res);
    });
};

const getLookupPersoneList = () => {
  return services.dbManag
    .post(`/combolist/get`, {
      keys: "atcq|ttt",
    })
    .then((res) => {
      let tipiAlboLookup = [];
      let tipiTessereLookup = [];

      res.data.result[0].albi_tipo.forEach((tipo) => {
        tipiAlboLookup.push(tipo);
      });
      res.data.result[1].tesseramento_tipo_tessere.forEach((tipo) => {
        tipiTessereLookup.push(tipo);
      });

      return {
        tipiAlboLookup,
        tipiTessereLookup,
      };
    })
    .catch((e) => {
      console.log("Errore!");
      console.log(e.res);
    });
};

const state = {
  lingueParlateLookup: [],
  tipiDocRilascioLookup: [],
  taglieLookup: [],
  tipiDocIdentitaLookup: [],
  statiNascitaLookup: [],
  statiCittadinanzaLookup: [],
  titoliStudioLookup: [],
  statiResidenzaLookup: [],
  istatComuniLookup: [],
  maschioFemminaLookup: [
    { id: "M", name: "M" },
    { id: "F", name: "F" },
  ],
  disabileLookup: [
    { id: true, name: "SI" },
    { id: false, name: "NO" },
  ],
  selectedProvinciaPersona: "",
  tipiAlboLookup: [],
  tipiTessereLookup: [],
};

const getters = {
  lingueParlateLookup: (state) => state.lingueParlateLookup,
  tipiDocRilascioLookup: (state) => state.tipiDocRilascioLookup,
  taglieLookup: (state) => state.taglieLookup,
  tipiDocIdentitaLookup: (state) => state.tipiDocIdentitaLookup,
  statiNascitaLookup: (state) => state.statiNascitaLookup,
  statiCittadinanzaLookup: (state) => state.statiCittadinanzaLookup,
  istatRegioniLookup: (state) => state.istatRegioniLookup,
  titoliStudioLookup: (state) => state.titoliStudioLookup,
  statiResidenzaLookup: (state) => state.statiResidenzaLookup,
  maschioFemminaLookup: (state) => state.maschioFemminaLookup,
  disabileLookup: (state) => state.disabileLookup,
  selectedProvinciaPersona: (state) => state.selectedProvinciaPersona,
  tipiAlboLookup: (state) => state.tipiAlboLookup,
  tipiTessereLookup: (state) => state.tipiTessereLookup,
};
const actions = {
  lookupsFromApi: async (state) => {
    let res = await getLookup();
    state.commit("setLookUps", res);
  },
  lookupsPersoneListFromApi: async (stateObj) => {
    const { state } = stateObj;
    if (
      state.tipiAlboLookup.length === 0 ||
      state.tipiTessereLookup.length === 0
    ) {
      let res = await getLookupPersoneList();
      stateObj.commit("setLookUpsPersoneList", res);
    }
  },
};
const mutations = {
  setLookUps: (
    state,
    {
      lingueParlateLookup,
      tipiDocRilascioLookup,
      taglieLookup,
      tipiDocIdentitaLookup,
      statiNascitaLookup,
      statiCittadinanzaLookup,
      titoliStudioLookup,
      statiResidenzaLookup,
      // istatRegioniLookup,
      // istatComuniLookup,
    }
  ) => {
    lingueParlateLookup.forEach((element) => {
      state.lingueParlateLookup.push(element);
    });
    tipiDocRilascioLookup.forEach((element) => {
      state.tipiDocRilascioLookup.push(element);
    });
    taglieLookup.forEach((element) => {
      state.taglieLookup.push(element);
    });
    tipiDocIdentitaLookup.forEach((element) => {
      state.tipiDocIdentitaLookup.push(element);
    });
    statiNascitaLookup.forEach((element) => {
      state.statiNascitaLookup.push(element);
    });
    statiCittadinanzaLookup.forEach((element) => {
      state.statiCittadinanzaLookup.push(element);
    });
    titoliStudioLookup.forEach((element) => {
      state.titoliStudioLookup.push(element);
    });
    statiResidenzaLookup.forEach((element) => {
      state.statiResidenzaLookup.push(element);
    });
    // istatRegioniLookup.forEach((element) => {
    //   state.istatRegioniLookup.push(element);
    // });
    // istatComuniLookup.forEach((element) => {
    //   state.istatComuniLookup.push(element);
    // });
  },
  setLookUpsPersoneList: (state, { tipiAlboLookup, tipiTessereLookup }) => {
    tipiAlboLookup.forEach((element) => {
      state.tipiAlboLookup.push(element);
    });
    tipiTessereLookup.forEach((element) => {
      state.tipiTessereLookup.push(element);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
