import services from "./dbManag";

export const getDataFromApi = (keys, apiLink) => {
  return services.dbManag
    .post(apiLink, keys)
    .then((res) => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch((e) => {
      console.log(e.response);
    });
};
export const getDataFromListApi = (keys, apiLink, version) => {
  if (version == 2) {
    return services.dbManagV2
      .post(apiLink, keys)
      .then((res) => {
        const status = res.status;
        const { results, record, campi_Locked, comandi_Locked, read_only } =
          res.data;
        return {
          results,
          record,
          campi_Locked,
          comandi_Locked,
          status,
          read_only,
        };
      })
      .catch((e) => {
        return {
          results: [],
          record: 0,
          campi_Locked: [],
          comandi_Locked: [],
          status: e.request.status,
        };
      });
  } else {
    return services.dbManag
      .post(apiLink, keys)
      .then((res) => {
        const status = res.status;
        const { results, record, campi_Locked, comandi_Locked, read_only } =
          res.data;
        return {
          results,
          record,
          campi_Locked,
          comandi_Locked,
          status,
          read_only,
        };
      })
      .catch((e) => {
        return {
          results: [],
          record: 0,
          campi_Locked: [],
          comandi_Locked: [],
          status: e.request.status,
        };
      });
  }
};
