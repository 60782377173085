import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router/index";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import apiList from "./axios/apiLinks";

import "@/core/plugins/prismjs";
import "bootstrap";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { msalPlugin } from "@/plugins/msalPlugin";
import {
  msalInstance,
  loginRequest,
  silentTokenRequest,
  msalInstanceReset,
  resetPasswordRequest,
} from "./authConfig";
import { CustomNavigationClient } from "@/router/NavigationClient";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { BulkDelete } from "@/requests/BulkDelete";
import { checkTokenFromFCH } from "@/requests/checkTokenFromFCH";
import { alertFailed } from "./composables/swAlert";
import { routerKey } from "vue-router";

const url = window.location.href;
let utenteFCH = false;
let puoEntrareUtenteFCHStatus = 0;
const controlloTokenFCH = async () => {
  console.log("url contiene token da fch");
  const queryString = window.location.search;
  console.log(queryString);
  const urlParams = new URLSearchParams(queryString);
  const FCHT = urlParams.get("FCHT");
  console.log(FCHT);
  puoEntrareUtenteFCHStatus = window.location.href.includes("logout")
    ? 400
    : await checkTokenFromFCH(FCHT);
  localStorage.setItem("utenteFCH", utenteFCH ? "1" : "0");
  localStorage.setItem("FCHT", FCHT as string);
  localStorage.setItem(
    "puoEntrareUtenteFCHStatus",
    puoEntrareUtenteFCHStatus.toString()
  );
};

// id token
const appInsights = new ApplicationInsights({
  config: {
    // connectionString: 'Copy connection string from Application Insights Resource Overview'
    instrumentationKey: process.env.VUE_APP_APP_INSIGHT_INSTRUMENTATIONALKEY,
    enableAutoRouteTracking: true,
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

window.addEventListener("message", function (event) {
  // Verifica che l'origine del messaggio sia attendibile
  console.log("message", event);
  if (
    event.origin === "https://delightful-plant-0c6ff0103.3.azurestaticapps.net"
  ) {
    // Esegui il logout o altre azioni in base al messaggio ricevuto
    console.log("messaggio ricevuto da fch 1", event);
    if (event.data === "logout") {
      // Esegui il logout nel dominio 2
      // ...
      console.log("messaggio ricevuto da fch ed è logout", event);
    }
  }
});

const logout = async (goToFch = false) => {
  if (window.location.href.includes("logout")) {
    // const idTokenHint = localStorage.getItem("tokenAdb2c")
    //   ? localStorage.getItem("tokenAdb2c")
    //   : "err";
    // msalInstance.logoutRedirect({
    //   idTokenHint: idTokenHint as string,
    //   onRedirectNavigate: (url) => {
    //     // Return false if you would like to stop navigation after local logout
    //     return false;
    //   },
    // });
    return;
  }
  // localStorage.removeItem("tokenAdb2c");
  // store.commit("setUser", {});
  const idTokenHint = localStorage.getItem("tokenAdb2c") as string;

  if (goToFch) {
    await msalInstance.logoutRedirect({
      idTokenHint: idTokenHint,
      postLogoutRedirectUri: process.env.VUE_APP_URL_FCH,
    });
  } else {
    await msalInstance.logoutRedirect({
      idTokenHint: idTokenHint,
    });
  }
};
const unsetToken = () => {
  localStorage.removeItem("tokenAdb2c");
  store.commit("setToken", null);
};
const timestamp = Date.now();

// da implementare controllo api
// const oldLogin = false;
// se gia loggato una volta, prende token silent
// altrimenti controlla cookie logged_adb2c
if (accounts.length > 0) {
  if (!window.location.href.includes("logout")) {
    msalInstance.setActiveAccount(accounts[0]);
    msalInstance.acquireTokenSilent(silentTokenRequest).then((userInfo) => {
      unsetToken();
      localStorage.setItem("tokenAdb2c", userInfo.idToken);
      store.commit("setToken", userInfo.idToken);
      store.commit("setUser", msalInstance.getActiveAccount());
    });
  }
}

if (url.includes("FCHT")) {
  utenteFCH = true;
  controlloTokenFCH();
}
msalInstance.addEventCallback(async (event: any) => {
  // CONTROLLO SCADENZA TOKEN
  console.log(event);
  console.log(event.eventType);
  //if (event.eventType === EventType.HANDLE_REDIRECT_START) {
  //  await msalInstance.ssoSilent(loginRequest);
  //}

  if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    console.log(event);
    console.log(EventType.ACQUIRE_TOKEN_SUCCESS);
    console.log(localStorage.getItem("tokenAdb2c"));
    localStorage.setItem("tokenAdb2c", event.payload.idToken);
    if (
      localStorage.getItem("utenteFCH") == "0" ||
      !localStorage.getItem("utenteFCH")
    ) {
      // controllare se token di societa
      // se si, alert per andare su fch
      // altrimenti vai avanti
      const isValidTokenStatus = await checkTokenFromFCH(
        localStorage.getItem("tokenAdb2c")
      );
      if (isValidTokenStatus == 401) {
        router.push("/404");
        localStorage.setItem("goToFch", "1");
        //           "Dopo il lancio della nuovissima <br> <strong>FITP CONNECT HUB</strong>, sarà necessario effettuare l'autenticazione tramite questa piattaforma per accedere al Management.<br>Chiudendo il popup, verrai automaticamente reindirizzato alla nuova piattaforma.<br> Ti ricordiamo di utilizzare le medesime credenziali del management"

        alertFailed(
          "L’accesso al sistema può avvenire solo tramite <strong>FITP CONNECT HUB</strong>.<br>Chiudendo il pop-up sarai automaticamente reindirizzato alla pagina di login.<br>Ti ricordiamo che sarà necessario utilizzare le medesime credenziali del Management"
        ).then(async () => {
          await logout(true);
        });
      }
      return;
    } else {
      // controllare se puo entrare
      if (localStorage.getItem("puoEntrareUtenteFCHStatus") == "200") {
        return;
      } else {
        await logout(true);
      }
    }
    // const isValidTokenStatus = await checkTokenFromFCH();
    // if (isValidTokenStatus == 401) {
    //   // router.push("/404");
    //   localStorage.setItem("goToFch", "1");
    //   // alertFailed(
    //   //   "Dopo il lancio della nuovissima <br> <strong>FITP | CONNECT HUB</strong>, sarà necessario effettuare l'autenticazione tramite questa piattaforma per accedere al Management.<br>Chiudendo il popup, verrai automaticamente reindirizzato alla nuova <strong>FITP | CONNECT HUB</strong>"
    //   // ).then(async () => {
    //   //   // await logout(true);
    //   // });
    // }
  }
  if (event.eventType === EventType.SSO_SILENT_SUCCESS) {
    console.log(EventType.SSO_SILENT_SUCCESS);
  }
  if (event.eventType === EventType.SSO_SILENT_START) {
    console.log(EventType.SSO_SILENT_START);
  }
  if (
    event.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START &&
    accounts[0].idTokenClaims &&
    typeof accounts[0]?.idTokenClaims?.exp === "number" &&
    timestamp > accounts[0]?.idTokenClaims?.exp * 1000
  ) {
    // logout();

    await msalInstance
      .acquireTokenSilent(loginRequest)
      .then(async (userInfo) => {
        unsetToken();
        console.log(EventType.ACQUIRE_TOKEN_NETWORK_START);
        const isValidToken = await checkTokenFromFCH(
          localStorage.getItem("tokenAdb2c")
        );
        console.log("isValidToken", isValidToken);
        localStorage.setItem("tokenAdb2c", userInfo.idToken);
        await store.commit("setToken", userInfo.idToken);
        await store.commit("setUser", msalInstance.getActiveAccount());
      });
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error.errorMessage.includes("AADB2C90118")) {
      // router.push(`/500?adb2c=1`);
      msalInstanceReset.loginRedirect(resetPasswordRequest);
    } else logout();
    // logout();
  }
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    // ||
    // event.eventType === EventType.SSO_SILENT_FAILURE
    if (
      event.error.errorMessage.includes("AADB2C90077") &&
      window.location.href.includes("/sign-in")
    ) {
      return;
    } else {
      logout();
    }
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    await msalInstance
      .acquireTokenSilent(loginRequest)
      .then(async (userInfo: any) => {
        // unsetToken();
        localStorage.setItem("tokenAdb2c", userInfo.idToken);
        await BulkDelete({
          id_Utente: userInfo.idTokenClaims.sub,
          token: userInfo.idToken,
        });
        await store.commit("setIsLoggingAdb2c");
        await store.commit("setToken", userInfo.idToken);
        await store.commit("setUser", msalInstance.getActiveAccount());
      });
    // .finally(() => router.push("/bacheca-public"));
  }
});

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.config.globalProperties.$apiListGlobal = apiList;

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(msalPlugin, msalInstance);
router.isReady().then(async () => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});
