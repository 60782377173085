const getDefaultState = () => {
  return {
    id_societaBozzeOmologazione: null,
    denominazioneSocietaBozzeOmologazione: "",
    id_omologatoreBozzeOmologazione: null,
    denominazioneOmologatoreBozzeOmologazione: "",
    tipologieBozzeOmologazione: "7",
    disciplineBozzeOmologazione: null,
    rowstoskipBozzeOmologazione: 0,
    fetchrowsBozzeOmologazione: 25,
    currentpageBozzeOmologazione: 1,
    sortcolumnBozzeOmologazione: "societa",
    sortorderBozzeOmologazione: "asc",
    tutteLeStagioniBozzeOmologazione: false,
  };
};
const state = getDefaultState();

const getters = {
  id_societaBozzeOmologazione: (state) => state.id_societaBozzeOmologazione,
  id_omologatoreBozzeOmologazione: (state) =>
    state.id_omologatoreBozzeOmologazione,
  denominazioneSocietaBozzeOmologazione: (state) =>
    state.denominazioneSocietaBozzeOmologazione,
  denominazioneOmologatoreBozzeOmologazione: (state) =>
    state.denominazioneOmologatoreBozzeOmologazione,
  tipologieBozzeOmologazione: (state) => state.tipologieBozzeOmologazione,
  disciplineBozzeOmologazione: (state) => state.disciplineBozzeOmologazione,
  rowstoskipBozzeOmologazione: (state) => state.rowstoskipBozzeOmologazione,
  fetchrowsBozzeOmologazione: (state) => state.fetchrowsBozzeOmologazione,
  currentpageBozzeOmologazione: (state) => state.currentpageBozzeOmologazione,
  sortcolumnBozzeOmologazione: (state) => state.sortcolumnBozzeOmologazione,
  sortorderBozzeOmologazione: (state) => state.sortorderBozzeOmologazione,
  tutteLeStagioniBozzeOmologazione: (state) =>
    state.tutteLeStagioniBozzeOmologazione,
};

// const actions = {};
const mutations = {
  setid_societaBozzeOmologazione: (state, value) => {
    state.id_societaBozzeOmologazione = value;
  },
  setid_omologatoreBozzeOmologazione: (state, value) => {
    state.id_omologatoreBozzeOmologazione = value;
  },
  setdenominazioneSocietaBozzeOmologazione: (state, value) => {
    state.denominazioneSocietaBozzeOmologazione = value;
  },
  setdenominazioneOmologatoreBozzeOmologazione: (state, value) => {
    state.denominazioneOmologatoreBozzeOmologazione = value;
  },
  settipologieBozzeOmologazione: (state, value) => {
    state.tipologieBozzeOmologazione = value;
  },
  setdisciplineBozzeOmologazione: (state, value) => {
    state.disciplineBozzeOmologazione = value;
  },
  setfetchrowsBozzeOmologazione: (state, value) => {
    console.log(value);
    state.currentpageBozzeOmologazione = 1;
    state.rowstoskipBozzeOmologazione = 0;
    state.fetchrowsBozzeOmologazione = value;
  },
  setcurrentpageBozzeOmologazione: (state, value) => {
    state.currentpageBozzeOmologazione = value;
    state.rowstoskipBozzeOmologazione = parseInt(
      (state.currentpageBozzeOmologazione - 1) *
        state.fetchrowsBozzeOmologazione
    );
  },
  setsortcolumnBozzeOmologazione: (state, value) => {
    state.sortcolumnBozzeOmologazione = value;
  },
  setsortorderBozzeOmologazione: (state, value) => {
    state.sortorderBozzeOmologazione = value;
  },
  setrowstoskipBozzeOmologazione: (state) => {
    state.currentpageBozzeOmologazione = 1;
    state.rowstoskipBozzeOmologazione = 0;
  },
  setTutteLeStagioniBozzeOmologazione: (state) => {
    state.tutteLeStagioniBozzeOmologazione =
      !state.tutteLeStagioniBozzeOmologazione;
    state.currentpageBozzeOmologazione = 1;
    state.rowstoskipBozzeOmologazione = 0;
  },
  resetFiltersBozzeOmologazione: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
