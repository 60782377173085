import services from "../../axios/dbManag";

const getLookupStagioni = () => {
  return services.dbManag
    .post(`/combolist/get`, {
      keys: "as",
    })
    .then((res) => {
      let stagioni = [];

      res.data.result[0].affiliazioni_stagioni.forEach((stagione) => {
        stagioni.push(stagione);
      });

      return {
        stagioni,
      };
    })
    .catch((e) => {
      console.log("Errore!");
      console.log(e.res);
    });
};

const state = {
  stagioni: [],
  stagioniOptions: [],
  defaultStagione: new Date().getFullYear(),
  stagioneCorrente: true,
  stagioneSelected: new Date().getFullYear(),
};

const getters = {
  stagioni: (state) => state.stagioni,
  stagioniOptions: (state) => state.stagioniOptions,
  defaultStagione: (state) => state.defaultStagione,
  stagioneCorrente: (state) => state.stagioneCorrente,
  stagioneSelected: (state) => state.stagioneSelected,
};
const actions = {
  lookupsStagioniFromApi: async (state) => {
    let res = await getLookupStagioni();
    state.commit("setLookUpsStagioni", res);
  },
};
const mutations = {
  setLookUpsStagioni: (state, { stagioni }) => {
    if (!state.stagioni.length) {
      stagioni.forEach((element) => {
        state.stagioni.push(element);
        let tag = {
          value: element.id,
          label: element.label,
        };
        state.stagioniOptions.push(tag);
      });
    }
  },
  setStagioneSelected: (state, value) => {
    state.stagioneSelected = value;
  },
  setStagioneDefault: (state, value) => {
    state.defaultStagione = value;
  },
  setStagioneCorrente: (state, value) => {
    state.stagioneCorrente = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
