import axios from "axios";
import store from "../store/index";
import useDisableElement from "@/composables/disableElements";
import router from "@/router/index";

const baseUrl = process.env.VUE_APP_ENDPOINT_FUNCTIONS;
const baseUrlV2 = process.env.VUE_APP_ENDPOINT_FUNCTIONS_V2;
const baseUrlInfoQr = process.env.VUE_APP_ENDPOINT_QR;

let dbManagControlloTokenFCH = axios.create({
  baseURL: baseUrl,
  // headers: {
  //   Authorization: "Bearer " + localStorage.getItem("id_token"),
  // },
  /* other custom settings */
});

let dbManag = axios.create({
  baseURL: baseUrl,
  // headers: {
  //   Authorization: "Bearer " + localStorage.getItem("id_token"),
  // },
  /* other custom settings */
});
let numberOfAjaxCAllPending = 0;
dbManag.interceptors.request.use((config) => {
  store.state.NumberPendingRequests.numberOfAjaxCAllPending++;
  // numberOfAjaxCAllPending++;
  let token = store.getters.tokenAdb2c
    ? store.getters.tokenAdb2c
    : localStorage.getItem("tokenAdb2c");
  config.headers["Authorization"] = "bearer " + token;
  return config;
});
dbManag.interceptors.response.use(
  function (response) {
    store.state.NumberPendingRequests.numberOfAjaxCAllPending--;
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const actualRoute = router.currentRoute.value.name;
    // se la risposta contiene campi locked o read only
    if (response.data.campi_Locked || response.data.read_only) {
      // if (!response.data.read_only) { // test readonly
      // salva un oggetto contenente il route attuale, i campi locked e read only
      const campiLockedPageobj = {
        route: actualRoute,
        campi_Locked: response.data.campi_Locked,
        read_only: response.data.read_only,
      };
      store.state.CampiLocked.arrayCampiLocked.push(campiLockedPageobj);
      // chiama la funzione per bloccare i campi locked o attivare readonly se presente
      document.addEventListener(
        "DOMContentLoaded",
        useDisableElement(actualRoute, "dbManag")
      );
      if (response.config.url.includes("/anagrafiche/persone/view")) {
        const anagraficaCampiLockedSet =
          store.state.CampiLocked.arrayCampiLocked.some((element) =>
            element.route.includes("/anagrafica-persona")
          );
        if (!anagraficaCampiLockedSet) {
          const objAnagrafica = {
            route: "anagrafica-persona",
            campi_Locked: response.data.campi_Locked,
            read_only: response.data.read_only,
          };
          store.state.CampiLocked.arrayCampiLocked.push(objAnagrafica);
        }
      }
    }
    // let error = new Error("ERRORE PROVA STATUS 525");
    // error.response.data.statusCode = 525;
    // throw error;
    if (response.data.statusCode == 525) {
      router.push("/manutenzione");
    }
    // useDisableElement(actualRoute);
    return response;
  },
  function (error) {
    store.state.NumberPendingRequests.numberOfAjaxCAllPending--;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data.statusCode == 525) {
      router.push("/manutenzione");
    }
    return Promise.reject(error);
  }
);

let downloadFile = axios.create({
  baseURL: baseUrl,
  responseType: "blob",
  /* other custom settings */
});
// downloadFile.defaults.headers = {
//   Authorization: "bearer " + store.getters.tokenAdb2c,
//   "Content-Disposition": "attachment",
//   "Content-Type": "application/octet-stream; charset=utf-8",
// };
downloadFile.interceptors.request.use((config) => {
  let token = store.getters.tokenAdb2c;
  config.headers["Authorization"] = "bearer " + token;
  config.headers["Content-Disposition"] = "attachment";
  config.headers["Content-Type"] = "application/octet-stream; charset=utf-8";
  return config;
});

let sendFile = axios.create({
  baseURL: `${baseUrl}`,
  // data: bodyFormData,
  headers: { "Content-Type": "multipart/form-data" },

  /* other custom settings */
});
sendFile.interceptors.request.use((config) => {
  let token = store.getters.tokenAdb2c;
  config.headers["Authorization"] = "bearer " + token;
  return config;
});

let baseApi = axios.create({
  baseURL: `${baseUrl}/sistema`,
});
baseApi.interceptors.request.use((config) => {
  let token = store.getters.tokenAdb2c
    ? store.getters.tokenAdb2c
    : localStorage.getItem("tokenAdb2c");
  config.headers["Authorization"] = "bearer " + token;
  return config;
});
baseApi.interceptors.response.use(function (response) {
  if (response.data.menu) {
    response.data.menu[0].comandi_enabled.forEach((element) => {
      store.state.CampiLocked.campiEnabled.push(element);
    });
    //console.log(response, store.state.CampiLocked.campiEnabled);
  }
  return response;
});

let baseApiQrCode = axios.create({
  baseURL: baseUrlInfoQr,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("tokenAdb2c"),
  },
});

let dbManagV2 = axios.create({
  baseURL: baseUrlV2,
});
let numberOfAjaxCAllPendingV2 = 0;
dbManagV2.interceptors.request.use((config) => {
  store.state.NumberPendingRequests.numberOfAjaxCAllPendingV2++;
  // numberOfAjaxCAllPendingV2++;
  let token = store.getters.tokenAdb2c
    ? store.getters.tokenAdb2c
    : localStorage.getItem("tokenAdb2c");
  config.headers["Authorization"] = "bearer " + token;
  return config;
});
dbManagV2.interceptors.response.use(
  function (response) {
    store.state.NumberPendingRequests.numberOfAjaxCAllPendingV2--;
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const actualRoute = router.currentRoute.value.name;
    // se la risposta contiene campi locked o read only
    if (response.data.campi_Locked || response.data.read_only) {
      // if (!response.data.read_only) { // test readonly
      // salva un oggetto contenente il route attuale, i campi locked e read only
      const campiLockedPageobj = {
        route: actualRoute,
        campi_Locked: response.data.campi_Locked,
        read_only: response.data.read_only,
      };
      store.state.CampiLocked.arrayCampiLocked.push(campiLockedPageobj);
      // chiama la funzione per bloccare i campi locked o attivare readonly se presente
      document.addEventListener(
        "DOMContentLoaded",
        useDisableElement(actualRoute, "dbManagV2")
      );
      if (response.config.url.includes("/anagrafiche/persone/view")) {
        const anagraficaCampiLockedSet =
          store.state.CampiLocked.arrayCampiLocked.some((element) =>
            element.route.includes("/anagrafica-persona")
          );
        if (!anagraficaCampiLockedSet) {
          const objAnagrafica = {
            route: "anagrafica-persona",
            campi_Locked: response.data.campi_Locked,
            read_only: response.data.read_only,
          };
          store.state.CampiLocked.arrayCampiLocked.push(objAnagrafica);
        }
      }
    }
    // let error = new Error("ERRORE PROVA STATUS 525");
    // error.response.data.statusCode = 525;
    // throw error;
    if (response.data.statusCode == 525) {
      router.push("/manutenzione");
    }
    // useDisableElement(actualRoute);
    return response;
  },
  function (error) {
    store.state.NumberPendingRequests.numberOfAjaxCAllPendingV2--;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data.statusCode == 525) {
      router.push("/manutenzione");
    }
    return Promise.reject(error);
  }
);

const services = {
  dbManag,
  dbManagV2,
  baseApi,
  downloadFile,
  baseUrl,
  sendFile,
  baseApiQrCode,
  dbManagControlloTokenFCH,
};

export default services;
