const getDefaultState = () => {
  return {
    id_societaApprovateOmologazione: null,
    denominazioneSocietaApprovateOmologazione: "",
    id_omologatoreApprovateOmologazione: null,
    denominazioneOmologatoreApprovateOmologazione: "",
    tipologieApprovateOmologazione: "1",
    disciplineApprovateOmologazione: null,
    rowstoskipApprovateOmologazione: 0,
    fetchrowsApprovateOmologazione: 25,
    currentpageApprovateOmologazione: 1,
    sortcolumnApprovateOmologazione: "societa",
    sortorderApprovateOmologazione: "asc",
    tutteLeStagioniApprovateOmologazione: false,
  };
};
const state = getDefaultState();

const getters = {
  id_societaApprovateOmologazione: (state) =>
    state.id_societaApprovateOmologazione,
  id_omologatoreApprovateOmologazione: (state) =>
    state.id_omologatoreApprovateOmologazione,
  denominazioneSocietaApprovateOmologazione: (state) =>
    state.denominazioneSocietaApprovateOmologazione,
  denominazioneOmologatoreApprovateOmologazione: (state) =>
    state.denominazioneOmologatoreApprovateOmologazione,
  tipologieApprovateOmologazione: (state) =>
    state.tipologieApprovateOmologazione,
  disciplineApprovateOmologazione: (state) =>
    state.disciplineApprovateOmologazione,
  rowstoskipApprovateOmologazione: (state) =>
    state.rowstoskipApprovateOmologazione,
  fetchrowsApprovateOmologazione: (state) =>
    state.fetchrowsApprovateOmologazione,
  currentpageApprovateOmologazione: (state) =>
    state.currentpageApprovateOmologazione,
  sortcolumnApprovateOmologazione: (state) =>
    state.sortcolumnApprovateOmologazione,
  sortorderApprovateOmologazione: (state) =>
    state.sortorderApprovateOmologazione,
  tutteLeStagioniApprovateOmologazione: (state) =>
    state.tutteLeStagioniApprovateOmologazione,
};

// const actions = {};
const mutations = {
  setid_societaApprovateOmologazione: (state, value) => {
    state.id_societaApprovateOmologazione = value;
  },
  setid_omologatoreApprovateOmologazione: (state, value) => {
    state.id_omologatoreApprovateOmologazione = value;
  },
  setdenominazioneSocietaApprovateOmologazione: (state, value) => {
    state.denominazioneSocietaApprovateOmologazione = value;
  },
  setdenominazioneOmologatoreApprovateOmologazione: (state, value) => {
    state.denominazioneOmologatoreApprovateOmologazione = value;
  },
  settipologieApprovateOmologazione: (state, value) => {
    state.tipologieApprovateOmologazione = value;
  },
  setdisciplineApprovateOmologazione: (state, value) => {
    state.disciplineApprovateOmologazione = value;
  },
  setfetchrowsApprovateOmologazione: (state, value) => {
    console.log(value);
    state.currentpageApprovateOmologazione = 1;
    state.rowstoskipApprovateOmologazione = 0;
    state.fetchrowsApprovateOmologazione = value;
  },
  setcurrentpageApprovateOmologazione: (state, value) => {
    state.currentpageApprovateOmologazione = value;
    state.rowstoskipApprovateOmologazione = parseInt(
      (state.currentpageApprovateOmologazione - 1) *
        state.fetchrowsApprovateOmologazione
    );
  },
  setsortcolumnApprovateOmologazione: (state, value) => {
    state.sortcolumnApprovateOmologazione = value;
  },
  setsortorderApprovateOmologazione: (state, value) => {
    state.sortorderApprovateOmologazione = value;
  },
  setrowstoskipApprovateOmologazione: (state) => {
    state.currentpageApprovateOmologazione = 1;
    state.rowstoskipApprovateOmologazione = 0;
  },
  setTutteLeStagioniApprovateOmologazione: (state) => {
    state.tutteLeStagioniApprovateOmologazione =
      !state.tutteLeStagioniApprovateOmologazione;
    state.currentpageApprovateOmologazione = 1;
    state.rowstoskipApprovateOmologazione = 0;
  },
  resetFiltersApprovateOmologazione: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
