const getDefaultState = () => {
  return {
    id_societaInAttesaOmologazione: null,
    denominazioneSocietaInAttesaOmologazione: "",
    id_omologatoreInAttesaOmologazione: null,
    denominazioneOmologatoreInAttesaOmologazione: "",
    tipologieInAttesaOmologazione: "2",
    disciplineInAttesaOmologazione: null,
    rowstoskipInAttesaOmologazione: 0,
    fetchrowsInAttesaOmologazione: 25,
    currentpageInAttesaOmologazione: 1,
    sortcolumnInAttesaOmologazione: "societa",
    sortorderInAttesaOmologazione: "asc",
    tutteLeStagioniInAttesaOmologazione: false,
  };
};
const state = getDefaultState();

const getters = {
  id_societaInAttesaOmologazione: (state) =>
    state.id_societaInAttesaOmologazione,
  id_omologatoreInAttesaOmologazione: (state) =>
    state.id_omologatoreInAttesaOmologazione,
  denominazioneSocietaInAttesaOmologazione: (state) =>
    state.denominazioneSocietaInAttesaOmologazione,
  denominazioneOmologatoreInAttesaOmologazione: (state) =>
    state.denominazioneOmologatoreInAttesaOmologazione,
  tipologieInAttesaOmologazione: (state) => state.tipologieInAttesaOmologazione,
  disciplineInAttesaOmologazione: (state) =>
    state.disciplineInAttesaOmologazione,
  rowstoskipInAttesaOmologazione: (state) =>
    state.rowstoskipInAttesaOmologazione,
  fetchrowsInAttesaOmologazione: (state) => state.fetchrowsInAttesaOmologazione,
  currentpageInAttesaOmologazione: (state) =>
    state.currentpageInAttesaOmologazione,
  sortcolumnInAttesaOmologazione: (state) =>
    state.sortcolumnInAttesaOmologazione,
  sortorderInAttesaOmologazione: (state) => state.sortorderInAttesaOmologazione,
  tutteLeStagioniInAttesaOmologazione: (state) =>
    state.tutteLeStagioniInAttesaOmologazione,
};

// const actions = {};
const mutations = {
  setid_societaInAttesaOmologazione: (state, value) => {
    state.id_societaInAttesaOmologazione = value;
  },
  setid_omologatoreInAttesaOmologazione: (state, value) => {
    state.id_omologatoreInAttesaOmologazione = value;
  },
  setdenominazioneSocietaInAttesaOmologazione: (state, value) => {
    state.denominazioneSocietaInAttesaOmologazione = value;
  },
  setdenominazioneOmologatoreInAttesaOmologazione: (state, value) => {
    state.denominazioneOmologatoreInAttesaOmologazione = value;
  },
  settipologieInAttesaOmologazione: (state, value) => {
    state.tipologieInAttesaOmologazione = value;
  },
  setdisciplineInAttesaOmologazione: (state, value) => {
    state.disciplineInAttesaOmologazione = value;
  },
  setfetchrowsInAttesaOmologazione: (state, value) => {
    console.log(value);
    state.currentpageInAttesaOmologazione = 1;
    state.rowstoskipInAttesaOmologazione = 0;
    state.fetchrowsInAttesaOmologazione = value;
  },
  setcurrentpageInAttesaOmologazione: (state, value) => {
    state.currentpageInAttesaOmologazione = value;
    state.rowstoskipInAttesaOmologazione = parseInt(
      (state.currentpageInAttesaOmologazione - 1) *
        state.fetchrowsInAttesaOmologazione
    );
  },
  setsortcolumnInAttesaOmologazione: (state, value) => {
    state.sortcolumnInAttesaOmologazione = value;
  },
  setsortorderInAttesaOmologazione: (state, value) => {
    state.sortorderInAttesaOmologazione = value;
  },
  setrowstoskipInAttesaOmologazione: (state) => {
    state.currentpageInAttesaOmologazione = 1;
    state.rowstoskipInAttesaOmologazione = 0;
  },
  setTutteLeStagioniInAttesaOmologazione: (state) => {
    state.tutteLeStagioniInAttesaOmologazione =
      !state.tutteLeStagioniInAttesaOmologazione;
    state.currentpageInAttesaOmologazione = 1;
    state.rowstoskipInAttesaOmologazione = 0;
  },
  resetFiltersInAttesaOmologazione: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
